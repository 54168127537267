import React, { useEffect, useState } from "react";
import { sanityGet } from "../client.js";
import DashboardList from "../components/dashboardList.js";
import { H3 } from "../theme/primitives.js";

export default function Dashboard() {
  const [dashboardData, setDashboard] = useState(null);
  const [dashboardQuery, setDashboardQuery] = useState("");

  const handleChange = (e) => {
    setDashboardQuery(e.target.value);
  };

  useEffect(() => {
    sanityGet
      .fetch(
        `*[_type in ["application", "provider", "customer", "hosting", "cms"] && !(_id in path("drafts.**"))] | order(name asc) {
            name,
            customer->{
              name
            },
            _id,
            _type,
            hostingUrl,
            cmsUrl,
            cms->{
              name
            },
            hosting->{
              name
            }
          }`
      )
      .then((data) => setDashboard(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    document.title = `Haven · Dashboard`;
  }, []);

  const filteredDashboardData =
    dashboardData &&
    dashboardData.filter(
      (item) =>
        item.name.toLowerCase().includes(dashboardQuery.toLowerCase()) ||
        (item.customer &&
          item.customer.name
            .toLowerCase()
            .includes(dashboardQuery.toLowerCase())) ||
        (item.cms &&
          item.cms.name.toLowerCase().includes(dashboardQuery.toLowerCase())) ||
        (item.hosting &&
          item.hosting.name
            .toLowerCase()
            .includes(dashboardQuery.toLowerCase()))
    );

  const applications =
    dashboardData &&
    filteredDashboardData.filter((item) => item._type === "application");

  const customers =
    dashboardData &&
    filteredDashboardData.filter((item) => item._type === "customer");

  const hosts =
    dashboardData &&
    filteredDashboardData.filter((item) => item._type === "hosting");

  const cmss =
    dashboardData &&
    filteredDashboardData.filter((item) => item._type === "cms");

  const providers =
    dashboardData &&
    filteredDashboardData.filter((item) => item._type === "provider");

  return (
    <div className="min-h-screen">
      <h2 className="sr-only">Dashboard</h2>
      <input
        type="text"
        value={dashboardQuery}
        onChange={handleChange}
        className="box-border w-full p-4 mb-24 border placeholder-gray"
        placeholder="Search in haven"
      />
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        <div>
          <H3 className="sticky top-0 bg-white z-10">Applications</H3>
          <DashboardList index={applications} slug="application" />
        </div>
        <div>
          <H3 className="sticky top-0 bg-white z-10">Customers</H3>
          <DashboardList index={customers} slug="customer" />
        </div>
        <div>
          <H3 className="sticky top-0 bg-white z-10">Tech/CMSs</H3>
          <DashboardList index={cmss} slug="cms" />
        </div>
        <div>
          <H3 className="sticky top-0 bg-white z-10">Technical providers</H3>
          <DashboardList index={providers} slug="provider" />
        </div>
        <div>
          <H3 className="sticky top-0 bg-white z-10">Hosts</H3>
          <DashboardList index={hosts} url="hostingUrl" />
        </div>
      </div>
    </div>
  );
}
