import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, A } from "../theme/primitives";

export default function DashboardList({ index, slug, url }) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (index && index.length) {
    return (
      <>
        <ul>
          {index.map((listItem, idx) => {
            return (
              <li
                className={idx >= 10 && !isExpanded ? "sr-only" : "my-3"}
                key={listItem._id}
              >
                {slug ? (
                  <Link
                    to={"/" + slug + "/" + listItem._id}
                    className="hover:text-green transition-colors duration-100"
                  >
                    {listItem.name}
                  </Link>
                ) : url ? (
                  <A href={listItem[url]}>{listItem.name}</A>
                ) : (
                  listItem.name
                )}
              </li>
            );
          })}
        </ul>
        {index.length > 10 &&
          (isExpanded ? (
            <div className="mt-2 pt-4 bg-white border-t border-gray w-full sticky bottom-0">
              <Button onClick={() => setIsExpanded(!isExpanded)}>
                Collapse
              </Button>
            </div>
          ) : (
            <div className="mt-2 pt-4 bg-white border-t border-gray w-full">
              <Button onClick={() => setIsExpanded(!isExpanded)}>Expand</Button>
            </div>
          ))}
      </>
    );
  }
  return <p className="my-3">No results</p>;
}
