import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/header.js";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import Footer from "./components/footer.js";
import Routes from "./routes.js";

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <div className="text-tiny md:text-base min-h-screen">
          <Header />
          <main className="p-4 md:p-8 xl:py-16 xl:ml-64">
            <Routes />
          </main>
          <Footer />
        </div>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
