import React, { useState } from "react";
import { ReactComponent as ExternalLink } from "./externalLink.svg";

export function Button({ children, onClick, className, disabled, ...props }) {
  return (
    <button
      className={`border bg-light px-4 py-0 md:px-8 md:py-1 ${
        !disabled ? "hover:text-green" : null
      } ${disabled ? "opacity-50 cursor-not-allowed" : null} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

export function A({ href, children, className, ...props }) {
  return (
    <a
      className={`hover:text-green ${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children || href}
      <ExternalLink className="inline ml-1" />
    </a>
  );
}

export function H2({ children, className, ...props }) {
  return (
    <h2
      className={`text-4xl md:text-5xl border-b pb-4 mb-16 ${className}`}
      {...props}
    >
      {children}
    </h2>
  );
}

export function H3({ children, className, ...props }) {
  return (
    <h3
      className={`mb-1 pb-1 text-2xl md:text-3xl border-b ${className}`}
      {...props}
    >
      {children}
    </h3>
  );
}

export function H3Accordion({ title, children, className, ...props }) {
  const [accordionState, setAccordionState] = useState(false);

  return (
    <>
      <button
        className={`w-full text-left outline-none ${
          !accordionState ? "absolute left-0 right-0 md:static" : ""
        }`}
        onClick={() => {
          setAccordionState(!accordionState);
        }}
      >
        <H3
          className={`chevron ${
            accordionState ? "isExpanded" : ""
          } hover:border-green cursor-pointer md:hover:border-black md:cursor-default ${className}`}
          {...props}
        >
          {title}
        </H3>
      </button>
      <div className={`${!accordionState ? "sr-only" : ""} md:not-sr-only`}>
        {children}
      </div>
    </>
  );
}

export function DL({ children, className, ...props }) {
  return (
    <dl
      className={`grid grid-cols-1 sm:grid-cols-list gap-x-4 gap-y-2 mt-3 ${className}`}
      {...props}
    >
      {children}
    </dl>
  );
}

export function DT({ children, className, ...props }) {
  return (
    <dt className={`font-bold whitespace-no-wrap ${className}`} {...props}>
      {children}
    </dt>
  );
}

export function DD({ children, className, ...props }) {
  return (
    <dt className={`break-words overflow-x-hidden ${className}`} {...props}>
      {children}
    </dt>
  );
}
