import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./logout-button";

export default function Footer() {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated)
    return (
      <div className="p-4 md:p-8 xl:sr-only flex justify-between items-end">
        <LogoutButton />
      </div>
    );

  return null;
}
