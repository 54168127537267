import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./pages/dashboard.js";
import Customer from "./pages/customer.js";
import Application from "./pages/application.js";
import CMS from "./pages/cms.js";
import Provider from "./pages/provider.js";
import useSessionStorage from "./hooks/useSessionStorage.js";
import Loading from "./components/loading.js";
import AuthenticationForm from "./components/authentication-form.js";

const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const Routes = () => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  // Initialize token in sessionStorage.
  // Set "loading" as initial value (if no other value exists).
  const [token, setToken] = useSessionStorage("token", "loading");

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      setToken("null");
    }

    // Save
    if (isAuthenticated) {
      getAccessTokenSilently({ audience: auth0Audience })
        .then((data) => setToken(data))
        .catch((error) => {
          console.error({ error });
          setToken("failed");
        });
    }
  }, [isAuthenticated, isLoading, setToken, getAccessTokenSilently]);

  useEffect(() => {
    // Only set loading state if previous state was "null".
    // This makes sure that you don't have to wait for a token if it already exists in sessionStorage.
    if (isLoading && token === "null") setToken("loading");

    // getAccessTokenSilently can sometimes fail if it lacks consent.
    // If so, ask the user for consent:
    if (token === "failed") {
      getAccessTokenWithPopup({ audience: auth0Audience })
        .then((data) => setToken(data))
        .catch((error) => {
          console.error({ error });
          setToken("null");
        });
    }
  }, [token, isLoading, setToken, getAccessTokenWithPopup]);

  // Show loading state while waiting for auth0.
  // This makes sure that no components that might need the
  // access token is rendered before the token is available.
  if (token === "loading" || isLoading) return <Loading />;

  // Show login form if user is not logged in
  if (!isAuthenticated) return <AuthenticationForm />;

  return (
    <>
      <Route component={Dashboard} path="/" exact />
      <Route component={Customer} path="/customer/:id" />
      <Route component={Application} path="/application/:id" />
      <Route component={CMS} path="/cms/:id" />
      <Route component={Provider} path="/provider/:id" />
    </>
  );
};

export default Routes;
