import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  sanityGet,
  sanityPostComment,
  hubSpotGetCompanyData,
  hubSpotGetContactIds,
  hubSpotGetContactData,
} from "../client.js";
import Loading from "../components/loading.js";
import {
  Button,
  A,
  H2,
  H3,
  DL,
  DT,
  DD,
  H3Accordion,
} from "../theme/primitives.js";
import AuthenticationForm from "../components/authentication-form.js";

export default function Provider() {
  const [providerData, setProviderData] = useState(null);
  const [comments, setComments] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState(null);
  const [hubSpotCompanyData, setHubSpotCompanyData] = useState(null);
  const [hubSpotContactData, setHubSpotContactData] = useState(null);
  const { id } = useParams();
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };

  useEffect(() => {
    sanityGet
      .fetch(
        `*[_id == "${id}"]{
            name,
            _id,
            _updatedAt,
            comments,
            url,
            agreements,
            "applications": *[_type == "application" && !(_id in path("drafts.**")) && references("${id}")] | order(name asc) {
              name,
              _id,
              cms -> {
                name,
                cmsUrl,
                _id
              },
              cmsVersion,
              productionUrl,
              customer -> {
                name,
                _id
              },
            },
            cms[] -> {
              name,
              cmsUrl,
              _id
            },
            hubspotId
          }`
      )
      .then((data) => setProviderData(data[0]))
      .catch(console.error);
  }, [id]);

  useEffect(() => {
    document.title = `Haven · ${providerData?.name ?? "Customer"}`;
  }, [providerData]);

  const companyId = providerData?.hubspotId;
  useEffect(() => {
    if (companyId) {
      hubSpotGetCompanyData(companyId)
        .then((response) => {
          setHubSpotCompanyData(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });

      hubSpotGetContactIds(companyId)
        .then((response) => {
          const contactIdArray = response?.data?.results ?? null;
          return hubSpotGetContactData(contactIdArray);
        })
        .then((response) => {
          setHubSpotContactData(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [companyId]);

  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <AuthenticationForm />;
  }

  if (!providerData) return <Loading />;

  const companyInfoList = [
    {
      title: "Hubspot",
      text: "Open in Hubspot",
      href: `https://app.hubspot.com/contacts/7640149/company/${
        providerData?.hubspotId ?? ""
      }/`,
      exists: !!providerData?.hubspotId ?? false,
    },
    {
      title: "Website",
      href: providerData?.url ?? "",
      exists: !!providerData?.url ?? false,
    },
  ];

  const companyExtendedInfoList = [
    {
      title: "Worked with",
      text: `${providerData?.applications?.length} applications` ?? "",
      exists: !!providerData?.applications ?? false,
    },
    {
      title: "Industry",
      text: hubSpotCompanyData?.properties?.industry ?? "",
      exists: !!hubSpotCompanyData?.properties?.industry ?? false,
    },
    {
      title: "Type",
      text: hubSpotCompanyData?.properties?.type ?? "",
      exists: !!hubSpotCompanyData?.properties?.type ?? false,
    },
    {
      title: "Description",
      text: hubSpotCompanyData?.properties?.description ?? "",
      exists: !!hubSpotCompanyData?.properties?.description ?? false,
    },
    {
      title: "Country",
      text: hubSpotCompanyData?.properties?.country ?? "",
      exists: !!hubSpotCompanyData?.properties?.country ?? false,
    },
    {
      title: "City",
      text: hubSpotCompanyData?.properties?.city ?? "",
      exists: !!hubSpotCompanyData?.properties?.city ?? false,
    },
    {
      title: "Postal code",
      text: hubSpotCompanyData?.properties?.zip ?? "",
      exists: !!hubSpotCompanyData?.properties?.zip ?? false,
    },
  ];

  return (
    <>
      <H2 className="text-4xl md:text-5xl">{providerData?.name ?? null}</H2>
      <div className="-mt-12 mb-20">
        <Button
          className="sm:float-right mb-4 sm:mb-0"
          onClick={() => {
            window.open(
              `https://haven.sanity.studio/desk/customers;${providerData._id}`,
              "_blank"
            );
          }}
        >
          Edit in Sanity
        </Button>
        {providerData?._updatedAt && (
          <p>
            Last updated at{" "}
            {new Date(providerData._updatedAt).toLocaleDateString(
              undefined,
              dateOptions
            )}
          </p>
        )}
      </div>

      <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <H3>Company info</H3>
          <DL>
            {companyInfoList.map((ci) => {
              if (ci.exists) {
                return (
                  <React.Fragment
                    key={`info-${ci.title.toLowerCase().replace(/\s/g, "")}`}
                  >
                    <DT>{ci.title}</DT>
                    <DD>
                      {ci.href ? (
                        <A href={ci.href}>{ci.text}</A>
                      ) : (
                        <p>{ci.text}</p>
                      )}
                    </DD>
                  </React.Fragment>
                );
              }
              return null;
            })}
            {providerData?.cms?.[0] && (
              <>
                <DT>CMS'</DT>
                <DD>
                  <ul>
                    {providerData?.cms.map((cms) => (
                      <li key={`cms-${cms._id}`}>
                        <Link
                          className="hover:text-green"
                          to={`/cms/${cms._id}`}
                        >
                          {cms.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DD>
              </>
            )}
          </DL>
        </div>

        <div>
          <H3Accordion title="Extended info">
            <DL>
              {companyExtendedInfoList.map((ci) => {
                if (ci.exists) {
                  return (
                    <React.Fragment
                      key={`info-${ci.title.toLowerCase().replace(/\s/g, "")}`}
                    >
                      <DT>{ci.title}</DT>
                      <DD>
                        {ci.href ? (
                          <A href={ci.href}>{ci.text}</A>
                        ) : (
                          <p>{ci.text}</p>
                        )}
                      </DD>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </DL>
          </H3Accordion>
        </div>
        <div className="lg:col-span-2">
          <H3Accordion title="Projects">
            {providerData?.applications?.[0] ? (
              <>
                <table className="sr-only md:not-sr-only table-auto text-left my-2 min-w-full max-w-full">
                  <thead>
                    <tr className="pt-3 pb-2 align-baseline">
                      <th>Application name</th>
                      <th>CMS</th>
                      <th>Production URL</th>
                      <th>Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providerData.applications.map((app) => {
                      return (
                        <tr
                          className="border-t border-light align-baseline"
                          key={`app-${app._id}`}
                        >
                          <td className="pr-6 py-1 break-word">
                            <Link
                              className="hover:text-green"
                              to={`/application/${app._id}`}
                            >
                              {app?.name ?? "Unnamed"}
                            </Link>
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {app?.cms ? (
                              <Link
                                className="hover:text-green"
                                to={`/cms/${app.cms._id}`}
                              >
                                {app.cms.name} {app.cmsVersion}
                              </Link>
                            ) : (
                              "–"
                            )}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.productionUrl ? (
                              <A href={app.productionUrl}>
                                {app.productionUrl}
                              </A>
                            ) : (
                              "—"
                            )}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.customer ? (
                              <Link to={`/customer/${app.customer._id}`}>
                                {app.customer.name}
                              </Link>
                            ) : (
                              "—"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <DL className="md:invisible md:hidden">
                  {providerData.applications.map((app) => {
                    return (
                      <>
                        <DT>
                          <Link
                            className="hover:text-green"
                            to={`/application/${app._id}`}
                          >
                            {app?.name ?? "Unnamed"}
                          </Link>
                        </DT>
                        <DD>
                          <ul>
                            {app.cms && (
                              <Link
                                className="hover:text-green"
                                to={`/cms/${app.cms._id}`}
                              >
                                {app.cms.name} {app.cmsVersion}
                              </Link>
                            )}
                            {app.productionUrl && (
                              <li>
                                <A href={app.productionUrl}>
                                  {app.productionUrl}
                                </A>
                              </li>
                            )}
                            {app.customer && (
                              <li>
                                <Link
                                  className="hover:text-green"
                                  to={`/customer/${app.customer._id}`}
                                >
                                  {app.customer.name}
                                </Link>
                              </li>
                            )}
                          </ul>
                        </DD>
                      </>
                    );
                  })}
                </DL>
              </>
            ) : (
              <p className="my-2">
                There are no applications registered on this provider
              </p>
            )}
          </H3Accordion>
        </div>

        {/* Agreements */}

        <div className="lg:col-span-2">
          <H3Accordion title="Agreements / deals">
            {providerData?.agreements?.[0] ? (
              <>
                <table className="sr-only md:not-sr-only table-auto text-left my-2 min-w-full max-w-full">
                  <thead>
                    <tr className="pt-3 pb-2 align-baseline">
                      <th>Name</th>
                      <th>Monthly Price</th>
                      <th>Hourly price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providerData.agreements.map((deal) => {
                      return (
                        <tr
                          className="border-t border-light align-baseline"
                          key={`app-${deal._id}`}
                        >
                          <td className="pr-6 py-1 break-word">
                            <a
                              className="hover:text-green"
                              href={deal?.url ?? ""}
                            >
                              {deal?.name ?? "–"}
                            </a>
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.monthPrice
                              ? deal?.monthPrice?.toLocaleString()
                              : "–"}
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.hourPrice
                              ? deal?.hourPrice?.toLocaleString()
                              : "–"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <p className="my-2">
                There are no agreements registered with this provider
              </p>
            )}
          </H3Accordion>
        </div>

        <div>
          <H3Accordion title="Contacts">
            <DL>
              {hubSpotContactData?.results &&
                hubSpotContactData?.results.map((contact) => (
                  <React.Fragment key={`contact-${contact?.id}`}>
                    <DT>{`${contact?.properties?.firstname ?? ""} ${
                      contact?.properties?.lastname ?? ""
                    }`}</DT>
                    <DD>
                      <ul>
                        {contact?.properties?.jobtitle && (
                          <li>
                            <p>{contact?.properties?.jobtitle ?? ""}</p>
                          </li>
                        )}
                        {contact?.properties?.email && (
                          <li>
                            <a
                              className="hover:text-green"
                              href={`mailto:${
                                contact?.properties?.email ?? ""
                              }`}
                            >
                              {contact?.properties?.email ?? ""}
                            </a>
                          </li>
                        )}
                        {contact?.properties?.phone && (
                          <li>
                            <a
                              className="hover:text-green"
                              href={`tel:${contact?.properties?.phone ?? ""}`}
                            >
                              {contact?.properties?.phone ?? ""}
                            </a>
                          </li>
                        )}
                      </ul>
                    </DD>
                  </React.Fragment>
                ))}
            </DL>
          </H3Accordion>
        </div>

        <div>
          <H3>Comments</H3>
          <textarea
            className="border w-full h-40 focus:outline-none focus:border-green p-4 mt-5"
            placeholder="Write something that future you or your colleague won’t remember"
            defaultValue={providerData.comments}
            onInput={(e) => {
              setComments(e.target.value);
            }}
          />
          <Button
            onClick={
              comments
                ? () => {
                    sanityPostComment(
                      id,
                      comments || providerData.comments,
                      setCommentsStatus
                    );
                  }
                : undefined
            }
            disabled={!comments}
          >
            Save
          </Button>
          <p className="inline pl-4">{commentsStatus}</p>
        </div>
      </div>
    </>
  );
}
