import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = (footer) => {
  const { logout, user } = useAuth0();
  return (
    <>
      {user.picture && (
        <img
          className="rounded-full mr-4 xl:mr-0"
          src={user.picture}
          alt="Your avatar"
        />
      )}
      {(user.email ?? user.name) && (
        <p className="text-xs my-4 flex-grow">
          You are signed in as{" "}
          <span className="underline">{user.email ?? user.name}</span>
          <br />
          (trough Auth0)
        </p>
      )}
      <button
        className="border bg-light px-4 py-0 md:px-8 md:py-1 hover:text-green"
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Sign out
      </button>
    </>
  );
};

export default LogoutButton;
