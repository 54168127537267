import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  sanityGet,
  sanityPostComment,
  hubSpotGetCompanyData,
  hubSpotGetContactIds,
  hubSpotGetContactData,
  harvestGetInvoiceData,
} from "../client.js";
import Loading from "../components/loading.js";
import {
  Button,
  A,
  H2,
  H3,
  DL,
  DT,
  DD,
  H3Accordion,
} from "../theme/primitives.js";
import AuthenticationForm from "../components/authentication-form.js";

export default function Customer() {
  const [invoicedTotal, setInvoicedTotal] = useState(null);
  const [invoicedYTD, setInvoicedYTD] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [comments, setComments] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState(null);
  const [hubSpotCompanyData, setHubSpotCompanyData] = useState(null);
  const [hubSpotContactData, setHubSpotContactData] = useState(null);
  const { id } = useParams();
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };

  useEffect(() => {
    sanityGet
      .fetch(
        `*[_id == "${id}"]{
            name,
            _id,
            _updatedAt,
            comments,
            caseUrl,
            orgNr,
            sla,
            "applications": *[_type == "application" && !(_id in path("drafts.**")) && references("${id}")] | order(name asc) {
              name,
              _id,
              type -> {
                name
              },
              productionUrl,
              repository
            },
            harvestId,
            hubspotId
          }`
      )
      .then((data) => setCustomerData(data[0]))
      .catch(console.error);
  }, [id]);

  useEffect(() => {
    document.title = `Haven · ${customerData?.name ?? "Customer"}`;
  }, [customerData]);

  const companyId = customerData?.hubspotId;
  useEffect(() => {
    if (companyId) {
      hubSpotGetCompanyData(companyId)
        .then((response) => {
          setHubSpotCompanyData(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });

      hubSpotGetContactIds(companyId)
        .then((response) => {
          const contactIdArray = response?.data?.results ?? null;
          return hubSpotGetContactData(contactIdArray);
        })
        .then((response) => {
          setHubSpotContactData(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [companyId]);

  const harvestId = customerData?.harvestId;
  useEffect(() => {
    if (harvestId) {
      harvestGetInvoiceData(harvestId)
        .then((response) => {
          const invoices = response?.data.invoices ?? [];

          let iYTD = 0,
            iAll = 0;

          invoices.forEach((invoice) => {
            iAll += Number.parseInt(invoice.amount, 10);
            // Checking if the created year is equal to now year for calculating YearToDate (YTD)
            if (
              new Date(invoice.created_at).getFullYear() ===
              new Date().getFullYear()
            ) {
              iYTD += Number.parseInt(invoice.amount, 10);
            }
          });
          setInvoicedTotal(iAll);
          setInvoicedYTD(iYTD);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [harvestId]);

  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <AuthenticationForm />;
  }

  if (!customerData) return <Loading />;

  const companyInfoList = [
    {
      title: "Customer case",
      text: "View case(s)",
      href: customerData?.caseUrl ?? "",
      exists: !!customerData?.caseUrl ?? false,
    },
    {
      title: "Hubspot",
      text: "Open in Hubspot",
      href: `https://app.hubspot.com/contacts/7640149/company/${
        customerData?.hubspotId ?? ""
      }/`,
      exists: !!customerData?.hubspotId ?? false,
    },
    {
      title: "Harvest",
      text: "Open in Harvest",
      href: `https://netliferesearch.harvestapp.com/reports/clients/${
        customerData?.harvestId ?? ""
      }?kind=all_time`,
      exists: !!customerData?.harvestId ?? false,
    },
    {
      title: "Org.No.",
      text: customerData?.orgNr ?? "",
      href: `https://data.brreg.no/enhetsregisteret/oppslag/enheter/${
        customerData?.orgNr ?? ""
      }`,
      exists: !!customerData?.orgNr ?? false,
    },
    {
      title: "Invoiced total",
      text: `${invoicedTotal ? invoicedTotal.toLocaleString() : "–"} NOK`,
      exists: invoicedTotal !== null,
    },
    {
      title: "Invoiced this year",
      text: `${invoicedYTD ? invoicedYTD.toLocaleString() : "–"} NOK`,
      exists: invoicedYTD !== null,
    },
  ];

  const companyExtendedInfoList = [
    {
      title: "Industry",
      text: hubSpotCompanyData?.properties?.industry ?? "",
      exists: !!hubSpotCompanyData?.properties?.industry ?? false,
    },
    {
      title: "Type",
      text: hubSpotCompanyData?.properties?.type ?? "",
      exists: !!hubSpotCompanyData?.properties?.type ?? false,
    },
    {
      title: "Description",
      text: hubSpotCompanyData?.properties?.description ?? "",
      exists: !!hubSpotCompanyData?.properties?.description ?? false,
    },
    {
      title: "Country",
      text: hubSpotCompanyData?.properties?.country ?? "",
      exists: !!hubSpotCompanyData?.properties?.country ?? false,
    },
    {
      title: "City",
      text: hubSpotCompanyData?.properties?.city ?? "",
      exists: !!hubSpotCompanyData?.properties?.city ?? false,
    },
    {
      title: "Postal code",
      text: hubSpotCompanyData?.properties?.zip ?? "",
      exists: !!hubSpotCompanyData?.properties?.zip ?? false,
    },
  ];

  return (
    <>
      <H2 className="text-4xl md:text-5xl">{customerData?.name ?? null}</H2>
      <div className="-mt-12 mb-20">
        <Button
          className="sm:float-right mb-4 sm:mb-0"
          onClick={() => {
            window.open(
              `https://haven.sanity.studio/desk/customers;${customerData._id}`,
              "_blank"
            );
          }}
        >
          Edit in Sanity
        </Button>
        {customerData?._updatedAt && (
          <p>
            Last updated at{" "}
            {new Date(customerData._updatedAt).toLocaleDateString(
              undefined,
              dateOptions
            )}
          </p>
        )}
      </div>

      <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <H3>Company info</H3>
          <DL>
            {companyInfoList.map((ci) => {
              if (ci.exists) {
                return (
                  <React.Fragment key={`info-${ci.title.replace(/\s/g, "")}`}>
                    <DT>{ci.title}</DT>
                    <DD>
                      {ci.href ? (
                        <A href={ci.href}>{ci.text}</A>
                      ) : (
                        <p>{ci.text}</p>
                      )}
                    </DD>
                  </React.Fragment>
                );
              }
              return null;
            })}
          </DL>
        </div>

        <div>
          <H3Accordion title="Extended info">
            <DL>
              {companyExtendedInfoList.map((ci) => {
                if (ci.exists) {
                  return (
                    <React.Fragment key={`info-${ci.title.replace(/\s/g, "")}`}>
                      <DT>{ci.title}</DT>
                      <DD>
                        {ci.href ? (
                          <A href={ci.href}>{ci.text}</A>
                        ) : (
                          <p>{ci.text}</p>
                        )}
                      </DD>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </DL>
          </H3Accordion>
        </div>
        <div className="lg:col-span-2">
          <H3Accordion title="Applications">
            {customerData?.applications?.[0] ? (
              <>
                <table className="sr-only md:not-sr-only table-auto text-left my-2 min-w-full max-w-full">
                  <thead>
                    <tr className="pt-3 pb-2 align-baseline">
                      <th>Name</th>
                      <th>App type</th>
                      <th>Production URL</th>
                      <th>Git repository</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerData.applications.map((app) => {
                      return (
                        <tr
                          className="border-t border-light align-baseline"
                          key={`app-${app._id}`}
                        >
                          <td className="pr-6 py-1 break-word">
                            <Link
                              className="hover:text-green"
                              to={`/application/${app._id}`}
                            >
                              {app?.name ?? "Unnamed"}
                            </Link>
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {app?.type?.name ?? "—"}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.productionUrl ? (
                              <A href={app.productionUrl}>
                                {app.productionUrl}
                              </A>
                            ) : (
                              "—"
                            )}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.repository ? (
                              <A href={app.repository}>{app.repository}</A>
                            ) : (
                              "—"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <DL className="md:invisible md:hidden">
                  {customerData.applications.map((app) => {
                    return (
                      <React.Fragment key={`app-${app._id}`}>
                        <DT>
                          <Link
                            className="hover:text-green"
                            to={`/application/${app._id}`}
                          >
                            {app?.name ?? "Unnamed"}
                          </Link>
                        </DT>
                        <DD>
                          <ul>
                            {app.type?.name && <li>{app.type?.name}</li>}
                            {app.productionUrl && (
                              <li>
                                <A href={app.productionUrl}>
                                  {app.productionUrl}
                                </A>
                              </li>
                            )}
                            {app.repository && (
                              <li>
                                <A href={app.repository}>{app.repository}</A>
                              </li>
                            )}
                          </ul>
                        </DD>
                      </React.Fragment>
                    );
                  })}
                </DL>
              </>
            ) : (
              <p className="my-2">
                There are no applications registered on this customer
              </p>
            )}
          </H3Accordion>
        </div>

        {/* SLA contracts */}

        <div className="lg:col-span-2">
          <H3Accordion title="SLA">
            {customerData?.sla?.[0] ? (
              <>
                <table className="sr-only md:not-sr-only table-auto text-left my-2 min-w-full max-w-full">
                  <thead>
                    <tr className="pt-3 pb-2 align-baseline">
                      <th>Name</th>
                      <th>Monthly Price</th>
                      <th>Licensing</th>
                      <th>Hourly price</th>
                      <th>Response time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerData.sla.map((deal) => {
                      return (
                        <tr
                          className="border-t border-light align-baseline"
                          key={deal._key}
                        >
                          <td className="pr-6 py-1 break-word">
                            <a
                              className="hover:text-green"
                              href={deal?.driveUrl ?? ""}
                            >
                              {deal?.name ?? "Ingen avtales"}
                            </a>
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.monthPrice
                              ? deal?.monthPrice?.toLocaleString()
                              : "-"}
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.licensePrice
                              ? deal?.licensePrice?.toLocaleString()
                              : "-"}
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.hourPrice
                              ? deal?.hourPrice?.toLocaleString()
                              : "-"}
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {deal?.slaLevel ?? "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <p className="my-2">
                There are no SLA registered on this customer
              </p>
            )}
          </H3Accordion>
        </div>

        <div>
          <H3Accordion title="Contacts">
            <DL>
              {hubSpotContactData?.results &&
                hubSpotContactData?.results.map((contact) => (
                  <React.Fragment key={`contact-${contact.id}`}>
                    <DT>
                      {`${contact?.properties?.firstname ?? ""} ${
                        contact?.properties?.lastname ?? ""
                      }`}
                    </DT>
                    <DD>
                      <ul>
                        {contact?.properties?.jobtitle && (
                          <li>
                            <p>{contact?.properties?.jobtitle ?? ""}</p>
                          </li>
                        )}
                        {contact?.properties?.email && (
                          <li>
                            <a
                              className="hover:text-green"
                              href={`mailto:${
                                contact?.properties?.email ?? ""
                              }`}
                            >
                              {contact?.properties?.email ?? ""}
                            </a>
                          </li>
                        )}
                        {contact?.properties?.phone && (
                          <li>
                            <a
                              className="hover:text-green"
                              href={`tel:${contact?.properties?.phone ?? ""}`}
                            >
                              {contact?.properties?.phone ?? ""}
                            </a>
                          </li>
                        )}
                      </ul>
                    </DD>
                  </React.Fragment>
                ))}
            </DL>
          </H3Accordion>
        </div>

        <div>
          <H3>Comments</H3>
          <textarea
            className="border w-full h-40 focus:outline-none focus:border-green p-4 mt-5"
            placeholder="Write something that future you or your colleague won’t remember"
            defaultValue={customerData.comments}
            onInput={(e) => {
              setComments(e.target.value);
            }}
          />
          <Button
            onClick={
              comments
                ? () => {
                    sanityPostComment(
                      id,
                      comments || customerData.comments,
                      setCommentsStatus
                    );
                  }
                : undefined
            }
            disabled={!comments}
          >
            Save
          </Button>
          <p className="inline pl-4">{commentsStatus}</p>
        </div>
      </div>
    </>
  );
}
