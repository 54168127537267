import React, { useEffect, useState } from "react";
import {
  Button,
  A,
  H2,
  H3,
  DL,
  DT,
  DD,
  H3Accordion,
} from "../theme/primitives";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, Link } from "react-router-dom";
import {
  sanityGet,
  sanityPostComment,
  githubGetRepoData,
  githubGetContributorsData,
  pingdomGetCheckData,
} from "../client.js";
import AuthenticationForm from "../components/authentication-form.js";
import Loading from "../components/loading.js";

export default function Application() {
  const [applicationData, setApplicationData] = useState(null);
  const [pingdomData, setPingdomData] = useState(null);
  const [commentsStatus, setCommentsStatus] = useState(null);
  const [comments, setComments] = useState(false);
  const [repoData, setRepoData] = useState(null);
  const [contributors, setContributors] = useState([]);
  const { id } = useParams();

  const dateOptions = { day: "numeric", month: "short", year: "numeric" };
  const dateHourOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  useEffect(() => {
    sanityGet
      .fetch(
        `*[_id == "${id}"]{
            _updatedAt,
            _id,
            name,
            comments,
            customer -> {
              name,
              _id
            },
            type -> {
              name,
              _id
            },
            cms -> {
              name,
              cmsUrl,
              _id
            },
            cmsVersion,
            hosting -> {
              name,
              hostingUrl
            },
            providers[] -> {
              name,
              _id
            },
            repository,
            techStack[] {
              name,
              edited,
              version,
              _key
            },
            analyticsAccount,
            analyticsPlatform,
            productionAdminIp,
            productionAdminUrl,
            productionIp,
            productionUrl,
            stagingUrl,
            stagingAdminUrl,
            access,
            pingdomUptime,
            dns
          }`
      )
      .then((data) => setApplicationData(data[0]))
      .catch(console.error);
  }, [id]);

  useEffect(() => {
    document.title = `Haven · ${applicationData?.name ?? "Application"}`;
  }, [applicationData]);

  useEffect(() => {
    const repoUrl = applicationData?.repository ?? "";
    const owner = repoUrl
      .substring(repoUrl.indexOf("github.com/"))
      .split("/")[1];
    const repo = repoUrl
      .substring(repoUrl.indexOf("github.com/"))
      .split("/")[2];

    if (applicationData?.repository) {
      githubGetRepoData(owner, repo)
        .then((response) => {
          setRepoData(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });

      githubGetContributorsData(owner, repo)
        .then((response) => {
          setContributors(response.data);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }

    if (applicationData?.pingdomUptime) {
      pingdomGetCheckData(applicationData?.pingdomUptime)
        .then((response) => {
          console.log(response);
          setPingdomData(response.data?.check);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [applicationData]);

  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <AuthenticationForm />;
  }

  if (!applicationData) return <Loading />;

  return (
    <>
      <H2>{applicationData?.name ?? null}</H2>
      <div className="-mt-12 mb-20">
        <Button
          className="sm:float-right mb-4 sm:mb-0"
          onClick={() => {
            window.open(
              `https://haven.sanity.studio/desk/applications;${applicationData._id}`,
              "_blank"
            );
          }}
        >
          Edit in Sanity
        </Button>
        {applicationData?._updatedAt && (
          <p>
            Last updated at{" "}
            {new Date(applicationData._updatedAt).toLocaleDateString(
              undefined,
              dateOptions
            )}
          </p>
        )}
      </div>
      <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <H3>Application info</H3>
          <DL className="mb-8">
            {applicationData?.customer?.name && (
              <>
                <DT className="font-bold">Customer</DT>
                <DD>
                  <Link
                    className="hover:text-green"
                    to={`/customer/${applicationData.customer._id}`}
                  >
                    {applicationData.customer.name}
                  </Link>
                </DD>
              </>
            )}
            {applicationData?.type && (
              <>
                <DT>App type</DT>
                <DD>{applicationData.type.name}</DD>
              </>
            )}
            {applicationData?.hosting && (
              <>
                <DT>Hosted at</DT>
                <DD>
                  <A href={applicationData.hosting.hostingUrl}>
                    {applicationData.hosting.name}
                  </A>
                </DD>
              </>
            )}
            {applicationData?.cms && (
              <>
                <DT>CMS/Type</DT>
                <DD>
                  <Link
                    className="hover:text-green"
                    to={`/cms/${applicationData.cms._id}`}
                  >
                    {applicationData.cms.name} {applicationData.cmsVersion}
                  </Link>
                </DD>
              </>
            )}
            {applicationData?.providers?.[0] && (
              <>
                <DT>Technical providers</DT>
                <DD>
                  <ul>
                    {applicationData?.providers.map((provider) => (
                      <li key={`provider-${provider._id}`}>
                        <Link
                          className="hover:text-green"
                          to={`/provider/${provider._id}`}
                        >
                          {provider.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DD>
              </>
            )}
          </DL>

          <H3Accordion title="Tech stack">
            {applicationData?.techStack?.[0] ? (
              <DL>
                {applicationData.techStack.map((tech) => (
                  <React.Fragment key={tech._key}>
                    <DT>
                      {`${tech.name || "Unknown tech"} - ${tech.version || ""}`}
                    </DT>
                    <DD>{tech.edited || ""}</DD>
                  </React.Fragment>
                ))}
              </DL>
            ) : (
              <p className="py-2">
                No data has been given about the tech stack
              </p>
            )}
          </H3Accordion>
        </div>

        <div>
          <H3Accordion title="Extended info">
            <DL>
              {(applicationData?.productionUrl ||
                applicationData?.productionIp) && (
                <>
                  <DT>Production</DT>
                  <DD>
                    <ul>
                      {applicationData.productionUrl && (
                        <li>
                          <A href={applicationData.productionUrl}>
                            {applicationData.productionUrl}
                          </A>
                        </li>
                      )}
                      {applicationData.productionIp && (
                        <li>({applicationData.productionIp})</li>
                      )}
                    </ul>
                  </DD>
                </>
              )}
              {(applicationData?.productionAdminUrl ||
                applicationData?.productionAdminIp) && (
                <>
                  <DT>Admin</DT>
                  <DD>
                    <ul>
                      {applicationData.productionAdminUrl && (
                        <li>
                          <A href={applicationData.productionAdminUrl}>
                            {applicationData.productionAdminUrl}
                          </A>
                        </li>
                      )}
                      {applicationData.productionAdminIp && (
                        <li>({applicationData.productionAdminIp})</li>
                      )}
                    </ul>
                  </DD>
                </>
              )}
              {applicationData?.stagingUrl && (
                <>
                  <DT>Stage</DT>
                  <DD>
                    <A href={applicationData.stagingUrl}>
                      {applicationData.stagingUrl}
                    </A>
                  </DD>
                </>
              )}
              {applicationData?.stagingAdminUrl && (
                <>
                  <DT>Stage admin</DT>
                  <DD>
                    <A href={applicationData.stagingAdminUrl}>
                      {applicationData.stagingAdminUrl}
                    </A>
                  </DD>
                </>
              )}
              {applicationData?.analyticsPlatform && (
                <>
                  <DT>Analytics platform</DT>
                  <DD>{applicationData.analyticsPlatform}</DD>
                </>
              )}
              {applicationData?.analyticsAccount && (
                <>
                  <DT>Analytics account</DT>
                  <DD>{applicationData.analyticsAccount}</DD>
                </>
              )}
              {applicationData?.repository && (
                <>
                  <DT>Repo</DT>
                  <DD>
                    <A href={applicationData.repository}>
                      {applicationData.repository}
                    </A>
                  </DD>
                </>
              )}
              {typeof (repoData?.open_issues ?? undefined) !== "undefined" && (
                <>
                  <DT>Open issues/PRs</DT>
                  <DD>{repoData.open_issues}</DD>
                </>
              )}
              {contributors?.[0] && (
                <>
                  <DT>Contributors</DT>
                  <DD>
                    <ul>
                      {contributors.map((git) => (
                        <li key={`git-${git.id}`}>
                          <A href={git.html_url}>{git.login}</A>
                        </li>
                      ))}
                    </ul>
                  </DD>
                </>
              )}
              {applicationData?.access && (
                <>
                  <DT>Access info</DT>
                  <DD>{applicationData.access}</DD>
                </>
              )}
              {applicationData?.dns && (
                <>
                  <DT>DNS</DT>
                  <dd>
                    {applicationData?.dns?.url && (
                      <A href={applicationData.dns.url}>
                        {applicationData.dns.provider}
                      </A>
                    )}
                    {applicationData?.dns?.contact && (
                      <p>{applicationData.dns.contact}</p>
                    )}
                  </dd>
                </>
              )}
            </DL>
          </H3Accordion>
        </div>

        <div>
          <H3Accordion title="Availability">
            <ul className="my-2">
              {applicationData?.pingdomUptime && (
                <li className="mb-12">
                  <h4 className="inline mr-4">Pingdom:</h4>
                  {pingdomData?.status && (
                    <p className="inline">
                      Application is {pingdomData.status} (
                      {new Date(
                        pingdomData.lasttesttime * 1000
                      ).toLocaleDateString(undefined, dateHourOptions)}
                      )
                    </p>
                  )}
                  <Button
                    className="block mt-2"
                    onClick={() => {
                      window.open(
                        `https://my.pingdom.com/reports/uptime#check=${applicationData?.pingdomUptime}`,
                        "_blank"
                      );
                    }}
                  >
                    See in pingdom
                  </Button>
                </li>
              )}
              {/* TODO:
              <li className="mb-12">
                <h4 className="inline mr-4">Lighthouse</h4>
                <p className="inline">00% (X days ago)</p>
                <Button className="block mt-2" onClick={() => {}}>
                  Run Lighthouse scan
                </Button>
              </li>
              */}
              {/* TODO:
              <li>
                <Button className="block mt-2" onClick={() => {}}>
                  Generate uptime report
                </Button>
              </li>
              */}
            </ul>
          </H3Accordion>
        </div>

        <div>
          <H3>Comments</H3>
          <textarea
            className="border w-full h-40 focus:outline-none focus:border-green p-4 mt-5"
            placeholder="Write your shady notes that wouldn’t fit in a Readme file here"
            defaultValue={applicationData.comments}
            onInput={(e) => {
              setComments(e.target.value);
            }}
          />
          <Button
            onClick={
              comments
                ? () => {
                    sanityPostComment(
                      id,
                      comments || applicationData.comments,
                      setCommentsStatus
                    );
                  }
                : undefined
            }
            disabled={!comments}
          >
            Save
          </Button>
          <p className="inline pl-4">{commentsStatus}</p>
        </div>
      </div>
    </>
  );
}
