import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./logout-button";

const dataset =
  process?.env?.REACT_APP_SANITY_STUDIO_API_DATASET ?? "production";

export default function Header() {
  const { isAuthenticated } = useAuth0();

  return (
    <header className="p-4 pb-2 md:px-8 xl:p-8 xl:fixed xl:h-full xl:w-64 bg-white z-30 flex flex-col justify-between">
      <div>
        <strong className="block">Netlife Design</strong>
        <h1 className="text-3xl md:text-4xl leading-normal tracking-tight mb-6 -ml-1 inline">
          <Link
            to="/"
            className="hover:text-green transition-colors duration-100 font-medium font-sans"
          >
            Haven
          </Link>
        </h1>
        {dataset !== "production" && (
          <strong className="block">{dataset}</strong>
        )}
      </div>
      {isAuthenticated && (
        <div className="sr-only xl:not-sr-only">
          <LogoutButton />
        </div>
      )}
    </header>
  );
}
