import sanityClient from "@sanity/client";

// Public variables
const sanityProject = process.env.REACT_APP_SANITY_STUDIO_API_PROJECT_ID;
const sanityDataset = process.env.REACT_APP_SANITY_STUDIO_API_DATASET;

const proxyFetch = async ({ provider, url, body, method, customHeaders }) => {
  const proxyUrl = "/.netlify/functions/proxy";
  const token = sessionStorage.getItem("token");

  // Reload page to reset token if it's missing from sessionStorage
  if (!token) window.location.reload();

  return await fetch(proxyUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      apiProvider: provider,
      apiUrl: url,
      apiBody: body,
      apiMethod: method,
      customApiHeaders: customHeaders,
    }),
  });
};

export const sanityGet = sanityClient({
  projectId: sanityProject,
  dataset: sanityDataset,
  useCdn: false,
});

export const sanityPostComment = async (id, value, setFeedback) => {
  const clockNow = new Date();
  const hours = clockNow.getHours();
  const minutes = clockNow.getMinutes();
  const seconds = clockNow.getSeconds();

  const mutations = [
    {
      patch: {
        id: id,
        set: {
          comments: value,
        },
      },
    },
  ];

  try {
    const response = await proxyFetch({
      method: "POST",
      provider: "sanity",
      url: `https://${sanityProject}.api.sanity.io/v1/data/mutate/${sanityDataset}`,
      body: JSON.stringify({ mutations }),
    });

    if (response.status === 200) {
      setFeedback(`Successfully saved at${hours}:${minutes}+${seconds}s`);
    }
  } catch (error) {
    console.error(error.response);
    setFeedback(`${error.response.status}: ${error.response.data.error}`);
  }
};

export const githubGetRepoData = async (owner, repo) => {
  const response = await proxyFetch({
    provider: "git",
    url: `https://api.github.com/repos/${owner}/${repo}`,
  });
  return { data: await response.json() };
};

export const githubGetContributorsData = async (owner, repo) => {
  const response = await proxyFetch({
    provider: "git",
    url: `https://api.github.com/repos/${owner}/${repo}/contributors`,
  });
  return { data: await response.json() };
};

export const hubSpotGetCompanyData = async (companyId) => {
  const properties =
    "country,city,zip,type,description,industry,num_associated_deals,hs_object_id";
  const requestUrl = `https://api.hubapi.com/crm/v3/objects/companies/${companyId}?archived=false&properties=${properties}`;

  const response = await proxyFetch({
    provider: "hubspot",
    url: requestUrl,
  });
  return { data: await response.json() };
};

export const hubSpotGetContactData = async (contactIdArray) => {
  const requestUrl = `https://api.hubapi.com/crm/v3/objects/contacts/batch/read?archived=false`;
  const requestBody = () => {
    return JSON.stringify({
      inputs: contactIdArray.map((id) => ({ id })),
      properties: ["firstname", "lastname", "email", "phone", "jobtitle"],
    });
  };

  const response = await proxyFetch({
    method: "POST",
    provider: "hubspot",
    url: requestUrl,
    body: requestBody(),
  });
  return { data: await response.json() };
};

export const hubSpotGetContactIds = async (companyId) => {
  const requestUrl = `https://api.hubapi.com/crm-associations/v1/associations/${companyId}/HUBSPOT_DEFINED/2`;

  const response = await proxyFetch({
    provider: "hubspot",
    url: requestUrl,
    customHeaders: JSON.stringify({
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
    }),
  });
  return { data: await response.json() };
};

export const harvestGetInvoiceData = async (harvestId) => {
  const response = await proxyFetch({
    provider: "harvest",
    url: `https://api.harvestapp.com/api/v2/invoices?client_id=${harvestId}`,
  });
  return { data: await response.json() };
};

export const pingdomGetCheckData = async (checkId) => {
  const response = await proxyFetch({
    provider: "pingdom",
    url: `https://api.pingdom.com/api/3.1/checks/${checkId}`,
  });
  return { data: await response.json() };
};
