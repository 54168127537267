import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { sanityGet, sanityPostComment } from "../client.js";
import Loading from "../components/loading.js";
import {
  Button,
  A,
  H2,
  H3,
  DL,
  DT,
  DD,
  H3Accordion,
} from "../theme/primitives.js";
import AuthenticationForm from "../components/authentication-form.js";

export default function Customer() {
  const [cmsData, setcmsData] = useState(null);
  const [comments, setComments] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState(null);
  const { id } = useParams();
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };

  useEffect(() => {
    sanityGet
      .fetch(
        `*[_id == "${id}"]{
            name,
            _id,
            _updatedAt,
            comments,
            cmsUrl,
            statusUrl,
            support,
            "providers": *[_type == "provider" && !(_id in path("drafts.**")) && references("${id}")] | order(name asc) {
              name,
              _id
            },
            "applications": *[_type == "application" && !(_id in path("drafts.**")) && references("${id}")] | order(name asc) {
              name,
              _id,
              type -> {
                name
              },
              productionUrl,
              cmsVersion,
              customer -> {
                name,
                _id
              }
            }
          }`
      )
      .then((data) => setcmsData(data[0]))
      .catch(console.error);
  }, [id]);

  useEffect(() => {
    document.title = `Haven · ${cmsData?.name ?? "Customer"}`;
  }, [cmsData]);

  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <AuthenticationForm />;
  }

  if (!cmsData) return <Loading />;

  const cmsInfoList = [
    {
      title: "Documentation",
      text: cmsData?.cmsUrl,
      href: cmsData?.cmsUrl,
      exists: !!cmsData?.cmsUrl ?? false,
    },
    {
      title: "System status",
      text: cmsData?.statusUrl,
      href: cmsData?.statusUrl,
      exists: !!cmsData?.statusUrl ?? false,
    },
    {
      title: "Support",
      text: cmsData?.support,
      exists: !!cmsData?.support ?? false,
    },
  ];

  const applicationsWithVersion = [
    ...cmsData?.applications
      ?.filter((app) => app.cmsVersion)
      .map((app) => app.cmsVersion),
  ].sort();

  const cmsExtendedInfoList = [
    {
      title: "Used in",
      text: `${cmsData?.applications?.length} applications` ?? "",
      exists: !!cmsData?.applications ?? false,
    },
    {
      title: "Oldest used version",
      text: applicationsWithVersion[0] ?? "",
      exists: !!applicationsWithVersion.length > 0 ?? false,
    },
    {
      title: "Newest used version",
      text: applicationsWithVersion[applicationsWithVersion?.length - 1] ?? "",
      exists: !!applicationsWithVersion.length > 0 ?? false,
    },
  ];

  return (
    <>
      <H2 className="text-4xl md:text-5xl">{cmsData?.name ?? null}</H2>
      <div className="-mt-12 mb-20">
        <Button
          className="sm:float-right mb-4 sm:mb-0"
          onClick={() => {
            window.open(
              `https://haven.sanity.studio/desk/cmsType;${cmsData._id}`,
              "_blank"
            );
          }}
        >
          Edit in Sanity
        </Button>
        {cmsData?._updatedAt && (
          <p>
            Last updated at{" "}
            {new Date(cmsData._updatedAt).toLocaleDateString(
              undefined,
              dateOptions
            )}
          </p>
        )}
      </div>

      <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <H3>General info</H3>
          <DL>
            {cmsInfoList.map((ci) => {
              if (ci.exists) {
                return (
                  <React.Fragment
                    key={`info-${ci.title.toLowerCase().replace(/\s/g, "")}`}
                  >
                    <DT>{ci.title}</DT>
                    <DD>
                      {ci.href ? (
                        <A href={ci.href}>{ci.text}</A>
                      ) : (
                        <p>{ci.text}</p>
                      )}
                    </DD>
                  </React.Fragment>
                );
              }
              return null;
            })}
            {cmsData?.providers?.[0] && (
              <>
                <DT>Technical providers</DT>
                <DD>
                  <ul>
                    {cmsData?.providers.map((provider) => (
                      <li key={`provider-${provider._id}`}>
                        <Link
                          className="hover:text-green"
                          to={`/provider/${provider._id}`}
                        >
                          {provider.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DD>
              </>
            )}
          </DL>
        </div>

        <div>
          <H3Accordion title="Extended info">
            <DL>
              {cmsExtendedInfoList.map((ci) => {
                if (ci.exists) {
                  return (
                    <React.Fragment
                      key={`info-${ci.title.toLowerCase().replace(/\s/g, "")}`}
                    >
                      <DT>{ci.title}</DT>
                      <DD>
                        {ci.href ? (
                          <A href={ci.href}>{ci.text}</A>
                        ) : (
                          <p>{ci.text}</p>
                        )}
                      </DD>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </DL>
          </H3Accordion>
        </div>
        <div className="lg:col-span-2">
          <H3Accordion title="Applications">
            {cmsData?.applications?.[0] ? (
              <>
                <table className="sr-only md:not-sr-only table-auto text-left my-2 min-w-full max-w-full">
                  <thead>
                    <tr className="pt-3 pb-2 align-baseline">
                      <th>Name</th>
                      <th>CMS version</th>
                      <th>Production URL</th>
                      <th>Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cmsData.applications.map((app) => {
                      return (
                        <tr
                          className="border-t border-light align-baseline"
                          key={`app-${app._id}`}
                        >
                          <td className="pr-6 py-1 break-word">
                            <Link
                              className="hover:text-green"
                              to={`/application/${app._id}`}
                            >
                              {app?.name ?? "Unnamed"}
                            </Link>
                          </td>
                          <td className="pr-6 py-1 break-word">
                            {app?.cmsVersion ?? "—"}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.productionUrl ? (
                              <A href={app.productionUrl}>
                                {app.productionUrl}
                              </A>
                            ) : (
                              "—"
                            )}
                          </td>
                          <td className="pr-6 py-1 break-all">
                            {app?.customer ? (
                              <Link
                                className="hover:text-green"
                                to={`/customer/${app.customer?._id}`}
                              >
                                {app.customer?.name}
                              </Link>
                            ) : (
                              "—"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <DL className="md:invisible md:hidden">
                  {cmsData.applications.map((app) => {
                    return (
                      <React.Fragment key={app._id}>
                        <DT>
                          <Link
                            className="hover:text-green"
                            to={`/application/${app._id}`}
                          >
                            {app?.name ?? "Unnamed"}
                          </Link>
                        </DT>
                        <DD>
                          <ul>
                            {app?.cmsVersion && (
                              <li>
                                {cmsData?.name ?? null} {app.cmsVersion}
                              </li>
                            )}
                            {app.productionUrl && (
                              <li>
                                <A href={app.productionUrl}>
                                  {app.productionUrl}
                                </A>
                              </li>
                            )}
                            {app.customer && (
                              <li>
                                <Link
                                  className="hover:text-green"
                                  to={`/customer/${app.customer?._id}`}
                                >
                                  {app.customer?.name}
                                </Link>
                              </li>
                            )}
                          </ul>
                        </DD>
                      </React.Fragment>
                    );
                  })}
                </DL>
              </>
            ) : (
              <p className="my-2">
                There are no applications registered on this cms
              </p>
            )}
          </H3Accordion>
        </div>

        <div></div>

        <div>
          <H3>Comments</H3>
          <textarea
            className="border w-full h-40 focus:outline-none focus:border-green p-4 mt-5"
            placeholder="Write something that future you or your colleague won’t remember"
            defaultValue={cmsData.comments}
            onInput={(e) => {
              setComments(e.target.value);
            }}
          />
          <Button
            onClick={
              comments
                ? () => {
                    sanityPostComment(
                      id,
                      comments || cmsData.comments,
                      setCommentsStatus
                    );
                  }
                : undefined
            }
            disabled={!comments}
          >
            Save
          </Button>
          <p className="inline pl-4">{commentsStatus}</p>
        </div>
      </div>
    </>
  );
}
