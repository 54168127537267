import React from "react";
import LoginButton from "./login-button";

const AuthenticationForm = () => {
  return (
    <div className="">
      <h2 className="text-3xl md:text-4xl leading-normal tracking-tight">
        You are not logged in!
      </h2>
      <p className="mt-3">Please log in to enter this site</p>
      <div className="mt-6">
        <div className="inline mr-4">
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default AuthenticationForm;
